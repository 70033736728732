import axios from 'axios';
import { decode } from 'base-64';
import md5 from 'md5';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Navbar
} from 'react-bootstrap';
import './App.css';

function App() {

  // eslint-disable-next-line
  const [user, setUser] = useState('');

  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search)

  const emailBase64 = urlParams.get('token');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSendEmail = async () => {

    const data = {
      usuario: user.toLocaleLowerCase(),
      senha: md5(confirmPassword).toUpperCase()
    }

    const response = await axios.post('https://aws.gestaodevalidades.com.br/validades/usuarios/senha/atualizar', data);

    if (response.status === 200) {
      alert('Senha alterada com sucesso!');
    } else {
      alert('Erro ao alterar senha!');
    }

  }

  useEffect(() => {
    if (emailBase64) {
      const decoded = decode(emailBase64);
      setUser(decoded);
    }
  }, [emailBase64])


  return (
    <div className="App">

      <Navbar bg="light">
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Navbar.Brand>
            <img
              alt=""
              src="https://beta.gestaodevalidades.com.br/img/logo/logo.png"
              // width="30"
              height="60"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <div style={{ marginTop: '50px', marginBottom: '50px' }}>
          <h4>
            Alteração de Senha
          </h4>
        </div>
        <div style={{ marginTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              if (password && confirmPassword) {
                if (password.length >= 6) {
                  if (password === confirmPassword) {
                    handleSendEmail();
                  } else {
                    alert('Senhas diferentes!');
                  }
                } else {
                  alert('Senha deve ter no mínimo 6 caracteres!');
                }
              } else {
                alert('Preencha todos os campos!');
              }



            }}
          >
            <Form.Group className="mb-3 " >
              <Form.Label>Usuário</Form.Label>
              <Form.Control type="email" disabled value={user} autoComplete='username' />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nova Senha</Form.Label>
              <Form.Control type="password" autoFocus placeholder="Nova Senha" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete='new-password' />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Redigite a Nova Senha</Form.Label>
              <Form.Control type="password" placeholder="Redigite a Nova Senha" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} autoComplete='new-password' />
            </Form.Group>

            <Button style={{ backgroundColor: 'rgba(217,141,71,1)', color: 'white', border: 'none', marginTop: '40px' }} size="lg" type="submit">
              {!loading ? 'Confirmar Alteração de Senha' : 'Carregando...'}
            </Button>
          </Form>
        </div>
      </Container>

    </div>
  );
}

export default App;
